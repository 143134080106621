.property-hero {
    background: linear-gradient(135deg, #63b330, black);
    color: #fff;
    padding: 80px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .property-hero::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent);
    transform: rotate(45deg);
    animation: rotateBackground 20s linear infinite;
    z-index: 0;
  }
  
  .property-hero > * {
    position: relative;
    z-index: 1;
  }
  
  .hero-heading {
    color: #fff;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease-out forwards;
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .hero-text {
    font-size: 1.1rem;
    color: #f0f0f0;
    margin-bottom: 30px;
    animation: fadeInUp 1.2s ease-out forwards;
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .btn-primary {
    background-color: #63b330;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #4caf50;
    color: #000;
  }
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes rotateBackground {
    to {
      transform: rotate(405deg);
    }
  }
  
  .service-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .service-card i {
    font-size: 3rem;
    color: #63b330;
    margin-bottom: 20px;
    transition: color 0.3s ease;
  }
  
  .service-card h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .service-card p {
    font-size: 1rem;
  }
.benefits-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .benefits-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    font-size: 1rem;
    color: #333;
  }
  
  .benefits-list li::before {
    content: "✔";
    color: #63b330;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
    line-height: 1;
    top: 0;
    transform: translateY(-10%);
  }
  