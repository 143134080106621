
.benefit {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  .benefit:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  .benefit i {
    font-size: 3rem;
    color: #63b330;
    margin-bottom: 20px;
    transition: color 0.3s ease;
  }
  
  .benefit:hover i {
    color: black;
  }
  
  .benefit h5 {
    font-size: 1.3rem;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .benefit p {
    font-size: 1rem;
    margin-bottom: 0;
    text-align: center;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  
  .proj-selling-heading {
    text-align: center;
    color: black;
    letter-spacing: 2px;
    position: relative;
    margin-bottom: 30px;
  }
  .proj-selling-heading::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #63b330;
    margin: 10px auto 0;
    border-radius: 2px;
    transition: width 0.3s ease-in-out;
  }
  .proj-selling-heading:hover::after {
    width: 120px;
    background-color: #63b330;
  }
  .get-started {
    background: linear-gradient(135deg, #63b330, black);
    color: #fff;
    padding: 60px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .get-started .heading {
    color: white;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeInUp 1s ease-out forwards;
  }
  
  .get-started::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent);
    transform: rotate(45deg);
    animation: rotateBackground 20s linear infinite;
    z-index: 0;
  }
  
  .get-started > * {
    position: relative;
    z-index: 1;
  }
  .get-started .btn {
    display: inline-block;
    background-color: white;
    color: #63b330;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 15px 30px;
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .get-started .btn:hover {
    background-color: #4CAF50;
    color: white;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes rotateBackground {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .get-started p{
    color: white;
    font-size: 1.1rem;
  }
  .proj-selling-btn{
    color: #fff;
    background-color: black;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    padding: 10px 30px;
    border-radius: 4px;
    font-weight: 600;
  }
  .proj-selling-btn:hover {
    transform: translateY(-3px);
    background-color: #63b330 !important;
    color: white !important;
  }